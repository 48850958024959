import Vue from 'vue';
import Vconsole from 'vconsole'
if (process.env.NODE_ENV == 'development') {
  new Vconsole()
}
import {
  Button,
  Cell,
  CellGroup,
  Collapse,
  CollapseItem,
  Field,
  Icon,
  NavBar,
  Toast,
  Dialog,
} from 'vant';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@/api';
import '@/mixins';
import '@/assets/css/font.css'
import '@/assets/css/publicCss.css'
import VueClipboard from 'vue-clipboard2'
import filters from "./filters/index"
import { getToken } from './utils/getToken'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Carousel3d from 'vue-carousel-3d';
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { i18n, vantLocales } from './utils/lang';
vantLocales(i18n.locale);
Vue.use(Carousel3d);
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import "swiper/css/swiper.css"
import '@/utils/check-image.js'
// 添加全局filter
Object.keys(filters).map(v => {
  Vue.filter(v, filters[v])
})
import './permission'
import WXJSSDDK from "weixin-js-sdk";

Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Field);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(VueClipboard);
Vue.use(Toast);
Vue.use(Dialog);

Vue.prototype.$api = api
Vue.config.productionTip = false;
Vue.prototype.$Toast = Toast;
Vue.prototype.$wx = WXJSSDDK;
Vue.prototype.getToken = getToken//全局挂载,获取用户token
Vue.prototype.FingerprintJS = FingerprintJS;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
