<template>
  <div>
    <keep-alive v-if="keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-if="!keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    keepAlive() {
      return this.$route.meta.keepAlive
    },
  }
}
</script>

<style lang="less">
body {
  background-color: #F6F7F7;
  margin: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.van-icon-arrow-left:before {
  content: '\e668';
  color: #000;
  font-size: 20px;
}

.arrow-left {
  height: 20px;
  width: 20px;
}

.cell-title {
  font-size: 18px !important;
  font-family: SFPro-Medium, SFPro;
  font-weight: bold;
  ;
  color: #212121;
}

.cell-text {
  font-size: 16px;
  font-family: SFPro-Medium, SFPro;
  font-weight: 500;
  color: #212121;
}

.public-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.public-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 消息未读标记标记 */
.unread-msg {
  margin-left: 6px;
  width: 16px;
  height: 16px;
  background: #E73C2D;
  border-radius: 50%;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
</style>
