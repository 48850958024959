//日，月，年
export function timeFilter(val) {
  if (!val) return '';
  let minute = '';
  var judgeFn = new RegExp(/\s+/g);
  if (judgeFn.test(val)) {
    minute = val.split(' ')[1];
    val = val.split(' ')[0];
  }
  let date = val.split('-');
  // return date[2] + '-' + date[1] + '-' + (Number(date[0]) + 543) + ' ' + minute;
  return date[0] + '-' + date[1] + '-' + date[2] + ' ' + minute;
}


//3位数加.
export function priceFilter(num) {
  if (!num) return '';
  var str = num.toString();
  var reg = /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg, "$1.");
}

export function couponTypeFilter(type) {
  if (!type) return '';
  let typeName = '';
  switch (type) {
    case 1:
      typeName = "kupon diskon penuh";
      break;
    case 2:
      typeName = "Pengurangan kupon instan";
      break;
    default:
      typeName = "kupon diskon";
      break;
  }
  return typeName;
}

export default { timeFilter, priceFilter, couponTypeFilter }
