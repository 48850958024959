import request from "@/utils/request";

// 上传文件
export function upload(file) {
  const formData = new FormData();
  formData.append('file', file)
  return request({
    method: 'POST',
    url: '/admin/endpoint/put-productFile',
    data: formData,
  })
}
