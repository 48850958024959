import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login', //登录页
    meta: { keepAlive: false },
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/resetPwd',
    name: 'resetPwd', //忘记密码页
    meta: { keepAlive: false },
    component: () => import('../views/login/resetPwd.vue'),
  },
  {
    path: '/homePage',
    name: 'homePage', //首页
    meta: { keepAlive: false },
    component: () => import('../views/home/homePage.vue'),
  },
  {
    path: '/storeManagement',
    name: 'storeManagement', //店铺管理
    meta: { keepAlive: false },
    component: () => import('../views/home/storeManagement.vue'),
  },
  {
    path: '/writeOff',
    name: 'writeOff', //今日已核销订单
    component: () => import('../views/officialAccounts/writeOff.vue'),
  },
  {
    path: '/summaryDetails',
    name: 'summaryDetails', // 汇总明细
    component: () => import('../views/officialAccounts/summaryDetails.vue'),
  },
  {
    path: '/writeOffOrderDetails',
    name: 'writeOffOrderDetails', // 核销-订单详情
    component: () => import('../views/officialAccounts/writeOffOrderDetails.vue'),
  },
  {
    path: '/storeEdit',
    name: 'storeEdit', //店铺编辑
    meta: { keepAlive: false },
    component: () => import('../views/store/storeEdit.vue'),
  },
  {
    path: '/selectAddress',
    name: 'selectAddress', //选择店铺地址
    component: () => import('../views/store/selectAddress.vue'),
  },
  {
    path: '/informationInfo',
    name: 'informationInfo', //收款信息
    component: () => import('../views/store/goodsSort.vue'),
  },
  {
    path: '/updateStoreInfo',
    name: 'updateStoreInfo', //修改店铺信息
    component: () => import('../views/store/updateStoreInfo.vue'),
  },
  {
    path: '/goodsSort',
    name: 'goodsSort', //商品排序
    component: () => import('../views/store/goodsSort.vue'),
  },
  {
    path: '/reportData',
    name: 'reportData', //查看数据
    component: () => import('../views/report/reportData.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASE_API,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
