export async function getToken() {
  if (window.android) {
    const userInfo = JSON.parse(window.android.getUserInfo());
    this.token = userInfo.token;
  }
  else if (window.WebViewJavascriptBridge) {
    return await new Promise((resolve, reject) => {
      this.$bridge.callHandler('userToken', {}, (data) => {
        this.token = data
        resolve(data)
      })
    })
  } else if (this.$route.query.userToken) {
    this.token = this.$route.query.userToken
  }
}
