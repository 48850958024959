// zhTW.js
// 系统公共语言
const system = {
  systemName: '元气盲盒',
}

const menu = {
  home: "首页",
}

// 登录模块
const login = {
  tab: '登入',
  tab2: '注册',
  account: '账号',
  password: '密码',
  code: '验证码',
  accountPl: '请输入手机号',
  passwordPl: '请输入密码',
  passwordPl2: '请再次输入密码',
  codePl: '请输入验证码',
  sendCode: '发送验证码',
  resend: '重新发送',
  forgetPassword: '忘记登录密码？',
  login: '登入',
  loging: '登 入 中...',
  register: '注册',
  reset: '重置密码',
  resetHint: '请输入手机号或邮箱来获取验证码，并填写验证码以及新密码',
  rememberPwd: "记住密码",
}
// 商家-首页
const homePage = {
  wirteOff: '去核销订单',
  getStoreLink: '获取店铺链接',
  todayTotalScore: '今日总分润',
  todayBasicMoistureDistribution: '今日基础分润',
  todaySellShareProfit: '今日变卖分润',
  addStore: '添加到店铺',
  removeBlindbox: '移出盲盒',
  blindBoxAddedNum: '已添加盲盒数',
  browseStore: '浏览店铺'
}
// 公共文字
const publicTitle = {
  editStore: '店铺编辑',
  dropDownRefresh: '下拉即可刷新...',
  releaseRefresh: '释放即可刷新...',
  loading: '加载中...',
  noMore: '没有更多了',
  nothingHere: '这里什么都没有哦~',
  copySuccessfully: '复制成功',
  copyFailed: '复制失败',
  confirmBtn: '确认',
}
// 店铺编辑
const storeEdit = {
  logOut: '退出登录',
  storeLogo: '店铺LOGO',
  storeName: '店铺名称',
  goodsSort: '商品排序',
  customerServiceNumber: '客服联系号码',
  lineCode: 'LINE客服码',
  collectionInformation: '收款信息',
  uploadPicture: '上传图片',
  format: '只支持.jpg格式',
  inpStoreName: '请输入店铺名称',
  title1: '好的店铺名称具备以下特点：',
  title2: '1、简短易懂，避免雷同，避免生僻字，尽量体现主营商品；',
  title3: '2、凸显店铺特色，体现店长风格；',
  title4: '3、不适用国家法律法规禁止的违规词、敏感词；',
  title5: '4、建议12字以内，超出在店铺页可能展示不全',
  title6: '客服电话将展示在店铺内，提供给用户咨询使用',
  title7: '请填写银行卡号收款信息以便进行费用结算',
  uploadLineCode: '上传LINE二维码',
  fillLineNum: '填写LINE号',
  iptLineNum: '请输入LINE号',
  bankCardNumber: '銀行卡號',
  iptBankCardNumber: '请输入银行卡号',
  bankName: '开户银行',
  iptCardType: '请输入卡类型',
  bankUserName: '开户人姓名',
  iptBankUserName: '请输入开户人姓名',
  customerServicePhone: '客服电话',
  iptCustomerServicePhone: '请输入客服电话',

}
// 订单详情
const reportData = {
  titleName: '臺版元氣福袋',
  selectDate: '選擇日期',
  totalScoreRun: '總分潤',
  basicMoistureDistribution: '基礎分潤',
  sellShareProfit: '變賣分潤',
  filterType: '類型篩選',
  orderNum: '訂單編號',
  rechargeTime: '充值時間',
  buyerNickname: '買家昵稱',
  actualAmountPaid: '實付金額',
  exchangeLuckyCoins: '兌換幸運幣',
  dividedMoisturizedTotal: '分潤合計',
  runPercentage: '分潤提成',
  orderDetail: '訂單詳情',
  settlementData: '結算數據',
  labelAll: '全部'
}
// 商品排序
const goodsSort = {
  goodsSort: '商品排序',
  modifiedSuccessfully: '修改成功'
}
// 首页模块
const home = {
  title: '泰國元氣盲盒',
}

export default {
  menu: menu,
  login: login,
  system: system,
  home: home,
  homePage,
  publicTitle,
  storeEdit,
  reportData,
  goodsSort
}
