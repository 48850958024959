// th.js
// 系统公共语言
const system = {
  systemName: 'กล่องตาบอดพลัง',
}

const menu = {
  home: "หน้าแรก",
}

// 登录模块
const login = {
  tab: 'เข้าสู่ระบบ',
  tab2: 'ลงทะเบียน',
  account: 'บัญชี',
  password: 'รหัสผ่าน',
  code: 'รหัสยืนยัน',
  accountPl: 'กรุณากรอกหมายเลขโทรศัพท์',
  passwordPl: 'กรุณากรอกรหัสผ่าน',
  passwordPl2: 'กรุณากรอกรหัสผ่านอีกครั้ง',
  codePl: 'กรุณากรอกรหัสยืนยัน',
  sendCode: 'ส่งรหัสยืนยัน',
  resend: 'ส่งซ้ำ',
  forgetPassword: 'ลืมรหัสผ่านเหรอ?',
  login: 'เข้าสู่ระบบ',
  loging: 'เข้าสู่ระบบ...',
  register: 'ลงทะเบียน',
  reset: 'รีเซ็ตรหัสผ่าน',
  resetHint: 'กรุณากรอกหมายเลขโทรศัพท์มือถือหรืออีเมลของคุณเพื่อรับรหัสยืนยัน และกรอกรหัสยืนยันและรหัสผ่านใหม่',
  rememberPwd: 'จดจำรหัสผ่าน',
}
// 商家-首页
const homePage = {
  wirteOff: '去核销订单',
  getStoreLink: 'รับลิงค์ร้านค้า',
  todayTotalScore: 'กําไรรวมของวันนี้',
  todayBasicMoistureDistribution: 'ปันผลกําไรพื้นฐานของวันนี้',
  todaySellShareProfit: 'ขายวันนี้เพื่อแบ่งปันผลกําไร',
  addStore: 'เพิ่มในร้านค้า',
  removeBlindbox: 'เอาออก',
  blindBoxAddedNum: 'เพิ่มจํานวนกล่องสุ่มแล้ว',
  browseStore: 'เรียกดูร้านค้า'
}
// 公共文字
const publicTitle = {
  editStore: 'แก้ไขร้านค้า',
  dropDownRefresh: 'ดึงลงเพื่อรีเฟรช...',
  releaseRefresh: 'ปล่อยเพื่อรีเฟรช...',
  loading: 'กำลังดาวน์โหลด...',
  noMore: 'หมดแล้ว',
  nothingHere: 'เสียดายไม่พบอะไรเลย~',
  copySuccessfully: 'คัดลอกสำเร็จ',
  copyFailed: 'การคัดลอกล้มเหลว',
  confirmBtn: 'ยืนยัน',
}
// 店铺编辑
const storeEdit = {
  logOut: 'ออกจากระบบ',
  storeLogo: 'โลโก้ร้านค้า',
  storeName: 'ชื่อร้านค้า',
  goodsSort: 'ประเภทสินค้า',
  customerServiceNumber: 'เบอร์ติดต่อฝ่ายบริการลูกค้า',
  lineCode: 'ไอดีLINEฝ่ายบริการลูกค้า',
  collectionInformation: 'ข้อมูลชําระเงิน',
  uploadPicture: 'อัพโหลดรูปภาพ',
  format: 'รองรับเฉพาะรูปแบบ .jpg เท่านั้น',
  inpStoreName: 'กรุณาป้อนชื่อร้านค้า',
  title1: 'ชื่อธุรกิจที่ดีมีลักษณะดังต่อไปนี้: ',
  title2: '1. สั้นและเข้าใจง่ายหลีกเลี่ยงความคล้ายคลึงกันหลีกเลี่ยงคําที่หายากและพยายามสะท้อนถึงผลิตภัณฑ์หลัก ',
  title3: '2. เน้นลักษณะของร้านและสะท้อนสไตล์ของผู้จัดการร้าน',
  title4: '3. ไม่สามารถใช้ถ้อยคําที่ผิดกฎหมายและถ้อยคําที่ละเอียดอ่อนซึ่งต้องห้ามตามกฎหมายและข้อบังคับของประเทศได้',
  title5: '4. แนะนําให้อยู่ภายใน 12 คํานอกเหนือจากที่การแสดงผลอาจไม่สมบูรณ์ในหน้าร้านค้า',
  title6: 'เบอร์โทรศัพท์ฝ่ายบริการลูกค้าจะแสดงในร้านค้าเพื่อให้ผู้ใช้เพื่อขอคําปรึกษา',
  title7: 'โปรดกรอกบัญชีธนาคาร เพื่อดำเนินการชำระเงิน',
  uploadLineCode: 'อัพโหลดคิวอาร์โค้ด LINE',
  fillLineNum: 'กรอกไอดี LINE',
  iptLineNum: 'กรุณากรอกไอดี LINE',
  bankCardNumber: 'เลขบัญชี',
  iptBankCardNumber: 'โปรดป้อนบัญชีธนาคาร',
  bankName: 'ธนาคารของคุณ',
  iptCardType: 'กรุณากรอกประเภทบัตร',
  bankUserName: 'ชื่อเจ้าของบัญชี',
  iptBankUserName: 'กรุณากรอกชื่อเจ้าของบัญชี',
  customerServicePhone: 'เบอร์โทรฝ่ายบริกา',
  iptCustomerServicePhone: 'กรุณาป้อนเบอร์โทรฝ่ายบริการ',

}
// 订单详情
const reportData = {
  titleName: ' Lucky Bag เวอร์ชั่นประเทศไทย',
  selectDate: 'เลือกวันที่',
  totalScoreRun: 'กําไรรวม',
  basicMoistureDistribution: 'การแบ่งปันผลกําไรพื้นฐาน',
  sellShareProfit: 'ขายและแบ่งปันผลกําไร',
  filterType: 'การกรองประเภท',
  orderNum: 'เลขคำสั่งซื้อ',
  rechargeTime: 'เวลาชําระเงิน',
  buyerNickname: 'ชื่อเล่นของผู้ซื้อ',
  actualAmountPaid: 'จํานวนเงินที่ชำระ',
  exchangeLuckyCoins: 'แลกเปลี่ยน Lucky Coins',
  dividedMoisturizedTotal: 'ส่วนแบ่งกําไรทั้งหมด',
  runPercentage: 'ค่าคอมมิชชั่นการแบ่งปันผลกําไร',
  orderDetail: 'รายละเอียดการสั่งซื้อ',
  settlementData: 'ข้อมูลการเรียกเก็บเงิน',
  labelAll: 'ทั้งหมด'
}
// 商品排序
const goodsSort = {
  goodsSort: 'ประเภทสินค้า',
  modifiedSuccessfully: 'แก้ไขสำเร็จ'
}
// 首页模块
const home = {
  title: '泰國元氣盲盒',
}

export default {
  menu,
  login: login,
  system: system,
  home: home,
  homePage,
  publicTitle,
  storeEdit,
  reportData,
  goodsSort
}
