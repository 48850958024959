import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie'
import { getToken, removeToken, removeUserDetail, getUserDetail, getNodeId, getSessionId } from '@/utils/localStorage'
import { Toast } from 'vant';
Vue.use(Toast)
import router from '@/router';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    historyDetail: {},
    token: getToken() || '',
    userDetail: getUserDetail() || {},
    nodeId: getNodeId() || '',
    sessionId: getSessionId() || '',
    lastTimePath: '', //记录上一次路由地址
    toPath: "", //重定向到登录页前的路由地址
    whiteList: ['/', '/resetPwd', '/homePage', '/login'], // 白名单
    language: Cookies.get('language') || 'zhTW',
  },
  mutations: {
    setHistoryDetail(state, value) {
      state.historyDetail = value;
    },

    setUserToken(state, value) {
      state.token = value;
    },

    setUserDetail(state, value) {
      state.userDetail = value;
    },

    setNodeId(state, value) {
      state.nodeId = value;
    },

    setSessionId(state, value) {
      state.sessionId = value;
    },
    // H5页面修改页面返回登录页
    clarToLogin() {
      removeToken();
      removeUserDetail()
      let second = 3
      const timer = setInterval(() => {
        second--;
        if (second) {
          // toast.message = `密码修改成功 ${second} 秒后前往登錄頁!`;
          toast.message = `รีเซ็ตรหัสผ่านเสร็จสมบูรณ์`;
        } else {
          router.push('/');
          clearInterval(timer);
          // 手动清除 Toast
          Toast.clear();
        }
      }, 1000);
      const toast = Toast.fail({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        // message: "密码修改成功 3 秒后前往登錄頁!",
        message: `รีเซ็ตรหัสผ่านเสร็จสมบูรณ์`,
      });
    },
    toLogin() {
      removeToken();
      removeUserDetail()
      let second = 3;
      const timer = setInterval(() => {
        second--;
        if (second) {
          // toast.message = `您還未登錄 ${second} 秒后前往登錄頁!`;
          toast.message = `${second}`;
        } else {
          router.push({ name: "loginFit" });
          clearInterval(timer);
          // 手动清除 Toast
          Toast.clear();
        }
      }, 1000);
      const toast = Toast.fail({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        // message: "您還未登錄 3 秒后前往登錄頁!",
      });
    },

    updateLastTimePath(state, value) {
      state.lastTimePath = value
    },

    // 重定向登录页前的地址
    SET_TOPATH: (state, toPath) => {
      state.toPath = toPath
    },

    // 設置語言
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    }
  },

  actions: {
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    }
  },

  modules: {
  },
});
