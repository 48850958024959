import { addlog } from "@/api/public";
import { setNodeId, setSessionId } from "@/utils/localStorage";
export default {
  data() {
    return {
      token: "",
      deviceId: "",
    }
  },

  methods: {

  }
}
